import React from 'react'
import styles from './ready.module.css'
import Layout from '../components/layout'

const ReadyPage = () => (
  <Layout>
    <div className={styles.root}>
      <h1>Ready!</h1>
      <p>Thanks for signing up for Remote Jobs Club!</p>
      <p>You'll be hearing from me every Monday morning, 9 am (eastern).</p>

    </div>
  </Layout>
)

export default ReadyPage
